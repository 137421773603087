.AppointmentNoteAttachments {
  .appointment-note-attachments {
    height: 25px;
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;

    i.icon {
      font-size: 0.8em;
      height: auto;
      margin-right: 3px;
    }

    div {
      display: inline-block;
      position: relative;
      font-size: 11px;
      color: #5d5d5d;
    }
  }
  .ui.floating.multiple.dropdown.action-dropdown {
    padding: 0px;

    .menu {
      max-height: 350px;
      overflow-y: auto;
    }

    i.icon.image {
      margin-top: 1px !important;
    }
    .dropdown-title {
      color: #5281bf;
      direction: rtl;
    }
    .dropdown-info {
      margin-top: 5px;
      p {
        line-height: 16px;
        font-size: 12.6px;
        color: #726c6c;
      }
    }
  }

  .dropdown-item-name-container {
    display: flex;
    align-items: center;
    max-width: 300px;
  }

  .files-url-checkbox {
    margin-right: 10px;
  }

  button.ui.green.button.files-url-download-btn {
    width: 90%;
    display: block;
    margin: 5px auto;
  }
}
